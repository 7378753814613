<ion-item detail="false" [lines]="lines" class="ion-no-margin ion-no-padding" mode="ios" *ngIf="theme">
    <ion-label class="ion-no-margin ion-no-padding">
        <ion-text class="label-item-roboto-regular-13 ion-no-margin ion-no-padding" [ngClass]="labelClass" style="display: block">
            {{ title | translate }}
        </ion-text>

        <ion-text *ngIf="description && !descriptionButton" class="text-input-roboto-regular-16 ion-no-margin ion-no-padding wrap-text">
            {{ description | translate }}
        </ion-text>
        <br />
        <ion-text *ngIf="descriptionButton && milliseconds > 0" class="textButton" (click)="timeWindowValueChange()">
            {{ description | translate }} {{ futureTime | date: 'd/M/yy, h:mm a' }}
        </ion-text>
    </ion-label>
    <ion-toggle class="color" [checked]="checked" (ionChange)="itemValueChange($event)" slot="end" [disabled]="isSwitchDisabled"></ion-toggle>
</ion-item>
